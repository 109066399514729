<template>
  <!--
    The view for the Adventures Statistics
  -->
  <div class="installationDetail_Adventures">
    <Portlet title="Adventures">
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <div class="adventures-container">
          <label
            for="adventures"
            class="form-label"
          >Adventures</label>
          <Multiselect
            id="adventures"
            v-model="selectedAdventure"
            tag-placeholder="Not found"
            placeholder="Select Adventure"
            :options="adventures"
            label="title"
            select-label="title"
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="mb-3"
          />
          <label
            for="levels"
            class="form-label"
          >Levels</label>
          <Multiselect
            id="levels"
            v-model="selectedLevel"
            tag-placeholder="Not found"
            placeholder="Select Level"
            :options="adventureLevels"
            label="title"
            select-label="title"
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
          />
        </div>
        <div
          v-if="selectedAdventure"
          class="mt-4"
        >
          <p>Total: {{ selectedAdventure.completed + selectedAdventure.inProgress }}</p>
          <p>Completed: {{ selectedAdventure.completed }}</p>
          <p>In progress: {{ selectedAdventure.inProgress }}</p>
        </div>
        <div
          v-if="selectedLevel"
          class="mt-4"
        >
          <kendo-chart
            :series-defaults="{ type: 'column', gap: 0.5, spacing: 0.3 }"
            :category-axis="{ categories: selectedLevel.actions.map(action => action.title), labels: { rotation: 'auto' } }"
            :value-axis="{ labels: { format: '{0}' } }"
            :tooltip="{ visible: true, format: '{0}' }"
          >
            <kendo-chart-series-item
              :name="'Completed Actions'"
              :data="selectedLevel.actions.map(action => action.completed)"
            />
          </kendo-chart>

          <kendo-chart
            class="mt-4"
            :series-defaults="{ type: 'column' }"
            :category-axis="{ categories: selectedLevel.badges.map(badge => badge.title), labels: { rotation: 'auto' } }"
            :value-axis="{ labels: { format: '{0}' } }"
            :tooltip="{ visible: true, format: '{0}' }"
          >
            <kendo-chart-series-item
              :name="'Completed Badges'"
              :data="selectedLevel.badges.map(badge => badge.completed)"
              :color="'#d3902f'"
            />
          </kendo-chart>

          <kendo-chart
            class="mt-4"
            :series-defaults="{ type: 'column' }"
            :category-axis="{ categories: selectedLevel.awards.map(award => award.title), labels: { rotation: 'auto' } }"
            :value-axis="{ labels: { format: '{0}' } }"
            :tooltip="{ visible: true, format: '{0}' }"
          >
            <kendo-chart-series-item
              :name="'Completed Awards'"
              :data="selectedLevel.awards.map(award => award.completed)"
              :color="'#36A2EB'"
            />
          </kendo-chart>
        </div>
      </template>
    </Portlet>
  </div>
</template>

<script>
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: "InstallationDetailAdventures",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    responsiveKendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      adventures: [],
      selectedAdventure: null,
      selectedLevel: null,
    };
  },
  metaInfo() {
    return {
      title: 'Adventures'
    };
  },
  computed: {
    adventureLevels() {
      return this.selectedAdventure ? this.selectedAdventure.levels : []
    }
  },
  watch: {
    selectedAdventure() {
      this.selectedLevel = null
    }
  },
  mounted() {
    this.getStatistic()
  },
  methods: {
    getStatistic() {
      this.loading = true;
      this.axios.get(`/Installation/GetAdventureStatistic?installationId=${this.installationId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.adventures = response.data
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>